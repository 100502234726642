import React from "react"
//import i1 from "../../assets/images/logo194.png";
import i1 from "../../assets/images/logo202.png";
import i2 from "../../assets/images/os_grey2.png";
import i3 from "../../assets/images/twitter_grey2.png";
import i5 from "../../assets/images/discord.png";
import menuButton from "../../assets/images/menu_button.png";


function Nav() {
    return (
        <nav className="navbar1 navbar-expand-lg static-top header-a">

            <div className="container nav-container navColour">  
            <ul className="navbar-nav ml-auto">

                        <li className="nav-item dropdown">
                            <a className="nav-link dropdown-toggle socials" href="/#" id="navbarDropdown" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                            <img src={menuButton} alt="menu" className="menuButton"/></a>

                            <div className="dropdown-menu navSize" aria-labelledby="navbarDropdown">
                            <li style={{textAlign: "center"}}>
                            <a href="#project" className="nav-linkMobile">Project</a><br />
                            <a href="#cryptoday" className="nav-linkMobile">CryptoDay</a><br />
                            <a href="#attributes" className="nav-linkMobile">Attributes</a><br />
                            <a href="#gallery" className="nav-linkMobile">Gallery</a><br />
                            <a href="#roadmap" className="nav-linkMobile">Roadmap</a><br />
                            <a href="#howTo" className="nav-linkMobile">HowTo</a><br />
                            </li>
                            <a href="https://twitter.com/CryptoDaysIO"  rel="noreferrer"  target={"_blank"}><img src={i3} alt="logo" className="mobileLogo" /></a><br />
                            <a href="https://discord.gg/DfakSuqayx"  rel="noreferrer"  target={"_blank"}><img src={i5} alt="logo" className="mobileLogo" /></a><br />
                            <a href="https://opensea.io/collection/cryptodaysio"  rel="noreferrer" target={"_blank"}><img src={i2} alt="logo" className="mobileLogo" /></a>
                            </div>
                        </li>
                            
                    </ul>  
                    <ul className="ulSize">
                 <a href="https://www.cryptodays.io" ><img src={i1} alt="logo" className="logo " /></a>
                 <a href="https://twitter.com/CryptoDaysIO"  rel="noreferrer"  target={"_blank"} style={{visibility: "hidden"}}> <div class="twitterDiv"></div></a>
                 <a href="https://opensea.io/collection/cryptodaysio"  rel="noreferrer" target={"_blank"} style={{visibility: "hidden"}}><div class="openseaDiv"></div></a>
                 <a href="https://discord.gg/DfakSuqayx"  rel="noreferrer"  target={"_blank"} style={{visibility: "hidden"}}><div class="discordDiv"></div></a>
                 <li className="navMenu2">
                 <a href="#project" className="nav-link2">Project</a>
                 <p className="navDivider">|</p>
                    <a href="#cryptoday" className="nav-link2">CryptoDay</a>
                    <p className="navDivider">|</p>
                    <a href="#attributes" className="nav-link2">Attributes</a>
                    <p className="navDivider">|</p>
                    <a href="#gallery" className="nav-link2">Gallery</a>
                    <p className="navDivider">|</p>
                    <a href="#roadmap" className="nav-link2">Roadmap</a>
                    <p className="navDivider">|</p>
                    <a href="#howTo" className="nav-link2">HowTo</a>
                    </li>

                </ul> 
            </div>
        </nav>
        
    );
}

export default Nav;


/*
  <li className="navMenu">
                 <a href="https://www.twitter.com"  rel="noreferrer"  target={"_blank"}><img src={i3} alt="logo" className="twitter " /></a>
                 <a href="https://www.telegram.com"  rel="noreferrer"  target={"_blank"}><img src={i4} alt="logo" className="osLogo " /></a>
                 <a href="https://www.discord.com"  rel="noreferrer"  target={"_blank"}><img src={i5} alt="logo" className="osLogo " /></a>
                <a href="https://www.opensea.io"  rel="noreferrer" target={"_blank"}><img src={i2} alt="logo" className="osLogo " /></a>
                </li>
                */



                /*
                <a href="https://www.telegram.com"  rel="noreferrer"  target={"_blank"}><img src={i4} alt="logo" className="mobileLogo" /></a><br />
                */