import React from "react";
import butterfly from "../../assets/images/butterfly.png";
import nftcalendar from "../../assets/images/nftcalendar.png";
import rarity from "../../assets/images/raritysniper_logo.png";

function Content3() {
    return (
        
        <div class = "content3">

            <section class=" content5">
                <div class="container ">
                <br /><br /><br /><br />
              
                </div>
            </section>

            <div className="container content container2" style={{ textAlign: "center" }}>
                <div className="row">
                <div id="content5">
                        <img src={butterfly} alt="butterfly"/>
                        </div>
                    <div className="col-sm-12 talk2" id="howTo">
                        <h3 className="h3Padding">How do I get my CryptoDay?</h3>
                        <br /><br /><br />
                        <h6 className="no-bold text howToPadding">
                        


1. Download and install the browser plugin <a href="https://metamask.io/index.html" className="alink">MetaMask</a> <br />
<br />
2. Create a new Wallet and setup up the Ethereum Blockchain<br />
<br />
3. Get some Ethereum to your Wallet<br />
<br />
4. Open <a href="https://www.CryptoDays.io" className="alink">www.CryptoDays.io</a> and login to Metamask<br /> <p className="size"> if not recognized, please refresh the page </p>
<br />
5. Click CONNECT and choose your favorite day from the dropdown, <br />the page will check if it's still available<br />
<br />
6. Click BUY and approve the transaction within Metamask <br />
<br /><br />
Save your BirthDay - it will be gone fast :) <br />
                    </h6>
                        <br /><br /><br />
                        <br /><br /><br />
                        <div>as seen on:</div>
                    <a href="https://nftcalendar.io/event/cryptodays-nft-calendar-free-mint/"  rel="noreferrer"  target={"_blank"}><img src={nftcalendar} alt="logo" className="nftcalendar" /></a>
                    <a href="https://nftcalendar.io/event/cryptodays-nft-calendar-free-mint/"  rel="noreferrer"  target={"_blank"}><img src={rarity} alt="logo" className="nftcalendar" /></a><br />
                    <br /><br /><br />
                    </div>

                </div>
            </div>

        </div>
    );
}

export default Content3;