import React from "react";
import ballon from "../../assets/images/ballon.png";
import body_preview from "../../assets/images/body_preview2.png";

function Content3() {
    return (
        <div class = "content3">
            <div className="container content container2" >
                <div className="row">
                <div id="content2">
                        <img src={ballon} alt="ballon" class="ribbon"/>
                        </div>
                    <div className="col-sm-12 talk2" id="cryptoday" style={{ textAlign: "center" }}>
                        <h3 className="h3Padding">The CryptoDay</h3>
                        <br /><br /><br />
                        <h6 className="no-bold text" >
                        
                        Each CryptoDay base consists of BODY, HEADER and DATE (YEAR-MONTH-DAY).<br /><br />
                        <ul class="d">
                            <li>15 x Header</li>
                            <li>25 x Body</li>
                            <li>23011 x DATE</li>
                        </ul>
                        <br />
                        <img src={body_preview} alt="body_preview" class="bodyPreview" />
                        <br /><br />
                        Every combination is randomly generated, each CryptoDay is created one time only.<br /><br />
                        ... and there are several attributes to sweeten up your CryptoDay!<br />
                    </h6>
                        <br /><br />
                    </div>

                </div>
            </div>

            <section class=" content5">
                <div class="container ">
               
              
                </div>
            </section>
        </div>
    );
}

export default Content3;