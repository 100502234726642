import React from "react"

function Footer() {
    return (
        <footer class="page-footer font-small light footer">

            
            <div class="container footer-copyright text-center py-3 navColour">Made with <span style={{"color": "#ff0000"}}>❤</span> by moepp
            
            <br /><br /><br /><br />
            </div>

        </footer>
    );
}

export default Footer;