import React from 'react';
import './App.css';
import Index from './views/Index/Index';
//Google Analytics
//import ReactGA from 'react-ga'
/*componentDidMount() {
  ReactGA.initialize('UA-2135491757-1');
  ReactGA.pageview("/")
}*/


function App() {

  return (
    <Index />
  );
}

export default App;
