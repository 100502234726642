import React, { useEffect, useState,useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { connect } from "../../redux/blockchain/blockchainActions";
import { fetchData } from "../../redux/data/dataActions";
import * as s from "../../styles/globalStyles";
import styled from "styled-components";
import i1 from "../../assets/images/start.gif";
//import present from "../../assets/images/present2.png";
import Select from 'react-select';
//import moment from "moment";

//import datum from './DatumConstants';
import datum from "../../data/datum.json";
import day from "../../data/day.json";
import month from "../../data/month.json";
import year from "../../data/year.json";

//import DatePicker from "react-datepicker";
//import "react-datepicker/dist/react-datepicker.css";

/*
const Example = () => {
  const [startDate, setStartDate] = useState(new Date());

  return (
    <DatePicker 
    selected={startDate} 
    placeholderText='PICK YOUR CDAY'
    minDate ={new Date("1971-01-01")}
    maxDate={new Date("2021-12-31")}
    peekNextMonth
    showMonthDropdown
    showYearDropdown
    dropdownMode="select"
    onChange={(date) => setStartDate(date)}
    currentDate={startDate}
    /> 
  );
  
};*/

export const StyledButton = styled.button`
  padding: 10px;
  border-radius: 50px;
  border: none;
  background-color: #ffffff;
  padding: 10px;
  font-weight: bold;
  color: #000000;
  width: 100px;
  cursor: pointer;
  box-shadow: 0px 6px 0px -2px rgba(250, 250, 250, 0.3);
  -webkit-box-shadow: 0px 6px 0px -2px rgba(250, 250, 250, 0.3);
  -moz-box-shadow: 0px 6px 0px -2px rgba(250, 250, 250, 0.3);
  :active {
    box-shadow: none;
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
  }
`;

export const ResponsiveWrapper = styled.div`
display: flex;
flex-direction: column;
  width: 100%;
  padding-bottom:none;

  @media (min-width: 811px) {
    flex-direction: row;
    padding-bottom:30px !important;
  }

`;
 //height: 200px;
export const StyledImg = styled.img`
  width: 60%;
 
  @media (min-width: 811px) {
    width: 100%;
    
  }
  transition: width 0.5s;
  transition: height 0.5s;
`;

export const PreviewImg = styled.img`
  width: 100px;
  height: 100px;
  @media (min-width: 50px) {
    width: 100px;
    height: 100px;
  }
  transition: width 0.5s;
  transition: height 0.5s;
`;


function Content() {

  const [selectedValueDay, setSelectedValueDay] = useState("01");
 /* const handleChangeDay= e => {
    setSelectedValueDay(e.value);
    refreshFeedback();   
    
  }*/

  const [selectedValueMonth, setSelectedValueMonth] = useState("01");
  /*const handleChangeMonth = e => {
    refreshFeedback();
    setSelectedValueMonth(e.value);
  }*/
  // set value for default selection
  const [selectedValueYear, setSelectedValueYear] = useState(1971);
/*  const handleChangeYear = e => {
    refreshFeedback();
    setSelectedValueYear(e.value);
  }*/

  
 /* function refreshFeedback() {
    CheckList(handleDatum(selectedValueDay,selectedValueMonth,selectedValueYear));
    }*/

  

  function userExists(id) {
    return datum.some(function(el) {
      return el.value === id;
    }); 
  }

  function handleDatum(day,month,year){
    const date  = '' + day + '-' + month + '-' + year;

   // console.log("day:"+day);
    //console.log("month:"+month);
    //console.log("year:"+year);

    // var value=datum.find(obj => obj.value === date)

    if (userExists(date)){
      var value=datum.find(obj => obj.value === date)
    }
    else {
      return 200000;
    }
    return value.label;
  }



  const dispatch = useDispatch();
  const blockchain = useSelector((state) => state.blockchain);
  const data = useSelector((state) => state.data);
  const [feedback, setFeedback] = useState("");
  const [claimingNft, setClaimingNft] = useState(false);
  const [buttonVariable, setButtonVariable] = useState(true);

  const firstUpdate = useRef(true);
  useEffect(() => {
    if (firstUpdate.current) {
      firstUpdate.current = false;
      return;
    }
  
    checkNFTs(handleDatum(selectedValueDay,selectedValueMonth,selectedValueYear));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedValueDay,selectedValueMonth,selectedValueYear]
    );

  
 // const checkNFTs = (id) => {
   function checkNFTs(id){
    blockchain.smartContract.methods.ownerOf(id).call()
    //FUNKTION UMSCHREIBEN AUF TRUE FALSE
    .then((receipt) => {
      if(receipt==null){
       // console.log("ready "+ receipt);
        setButtonVariable(false);
        setFeedback(
          <div className="feedbackColorGreen"> "Ready to mint!"</div>)
      }
      else {
      //  console.log("sorry "+ receipt);
        setButtonVariable(true);
        setFeedback(
          <div className="feedbackColorRed"> "Sorry, already minted!"</div>)
      }   
    })
    .catch(error => {
      //console.log(error);
      if (!datum.some(e => e.label === id)){
        setButtonVariable(true);
        setFeedback(
         <div className="feedbackColorRed"> "Nice try, this day does not exist!"</div>)
      }
      else {
      setButtonVariable(false);
      setFeedback(
        <div className="feedbackColorGreen"> "Ready to mint!"</div>)
      }
    }); 
  }

  const claimNFTs = (date) => {

    if (date >= 23011) {
      return;
    }
    setFeedback("Minting your CryptoDay... ");
    setClaimingNft(true);
    blockchain.smartContract.methods
      .mint(blockchain.account, date)
      .send({
        gasLimit: "240000",
        //gas_multiplier:"120000",
        maxPriorityFeePerGas: null,
        maxFeePerGas: null, 
        //gasLimit: "285000",
        //hier contract angeben
        to: "0x5d9956C4924E792ECbd06f0AAbe085759fF19dAC",
        from: blockchain.account,
        //hier den ETH WERT eingeben, wert = costs aus Vertrag
        value: blockchain.web3.utils.toWei((0.006).toString(), "ether"),
      })
      .once("error", (err) => {
        console.log(err);
        setFeedback("Sorry, something went wrong please try again later.");
        setClaimingNft(false);
      })
      .then((receipt) => {
        //updateList(handleDatum(selectedValueDay,selectedValueMonth,selectedValueYear),0);
        setFeedback(
          <div className="feedbackColorGreen">"WOW, you now own a CryptoDay. go visit <a href="https://opensea.io/collection/cryptodaysio"  className="blink">OpenSea</a> to view it."</div>
        );
        setClaimingNft(false);
        setButtonVariable(true);
        dispatch(fetchData(blockchain.account));
      });
  };

  const getData = () => {
    if (blockchain.account !== "" && blockchain.smartContract !== null) {
      dispatch(fetchData(blockchain.account));
    }
  };

  useEffect(() => {
    getData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [blockchain.account]);



  return (
    //<s.Screen style={{ backgroundColor: "#383838" }}>
    <s.Screen className="content1 container container2 ">
      <s.Container flex={1} ai={"center"} className="containerPadding">

        <s.SpacerMedium />
        <ResponsiveWrapper flex={1} style={{ padding: 10 }}>
          <s.Container flex={1} jc={"center"} ai={"center"}>
            <StyledImg alt={"example"} src={i1} />

  </s.Container>
          <s.SpacerMedium />
          <s.Container 
           flex={1}
            jc={"center"}
            ai={"center"}
            className="mintContent"
          >
            {Number(data.totalSupply) === 18628 ? (
              <>
                <s.TextTitle style={{ textAlign: "center" }}>
                  The sale has ended.
                </s.TextTitle>
                <s.SpacerSmall />
                <s.TextDescription style={{ textAlign: "center" }}>
                  You can still find CryptoDays on{" "}
                  <a
                    rel="noreferrer"
                    target={"_blank"}
                    href={"https://opensea.io/collection/cryptodaysio"}
                  >
                    Opensea.io
                  </a>
                </s.TextDescription>
              </>
            ) : (
              <>
                     
                         
               
                      <s.TextHead className="textBack"> Mint Your CryptoDay<br />
                      </s.TextHead>
                     {/* <img src={present} alt="present" class="present"/>*/}
                     <s.SpacerXSmall />
                     <s.SpacerXSmall />
                <s.TextTitle style={{ textAlign: "center" }}>
                  1 CryptoDay costs
                </s.TextTitle>
                <s.TextTitle style={{ textAlign: "center" }}>
                  0.006 ETH
                </s.TextTitle>
               {/*  <s.TextTitle   className="blink_text" style={{ textAlign: "center",color: "green"}}>
                  Update will be released on July 10!
                </s.TextTitle>*/}
                <s.SpacerXSmall />
                 {/*<s.TextDescription style={{ textAlign: "center" }}>
                  Excluding gas fee.
                </s.TextDescription>*/}
                <s.SpacerSmall />
                <s.TextDescription style={{ textAlign: "center"}}>
                  {feedback}
                </s.TextDescription>
                <s.SpacerSmall />
                {blockchain.account === "" ||
                blockchain.smartContract === null ? (
                  <s.Container ai={"center"} jc={"center"}>
                    <s.TextDescriptionContract style={{ textAlign: "center"}}>
                    Etherscan: <a href="https://etherscan.io/address/0x5d9956c4924e792ecbd06f0aabe085759ff19dac"  rel="noreferrer"  target={"_blank"}>0x5d99...9dac</a>
                    </s.TextDescriptionContract>   
                    <s.TextDescription style={{ textAlign: "center" }}>
                    Connect to the Ethereum network <br/>and<br/> Choose your CryptoDay 
                    </s.TextDescription>
                    <s.SpacerSmall />
                    <button type="button" className="btn btn-outline-dark start start-two" href="#" onClick={(e) => {
                        e.preventDefault();
                        dispatch(connect());
                        getData();
                      }}>CONNECT
                    </button>
                    {blockchain.errorMsg !== "" ? (
                      <>
                        <s.SpacerSmall />
                        <s.TextDescription style={{ textAlign: "center" }}>
                          {blockchain.errorMsg}
                        </s.TextDescription>
                      </>
                    ) : null}
                  </s.Container>
                ) : (
                  <s.Container2 ai={"center"} jc={"center"} >

                                <div className="App">
                                <div className="h4">Year</div>
                                <Select className="select"
                                  placeholder="Year"
                                  value={year.find(obj => obj.value === selectedValueYear)} // set selected value
                                  options={year} // set list of the data
                                  //onChange={handleChangeYear} // assign onChange function
                                  onChange={e => setSelectedValueYear(e.value)} // assign onChange function
                                />

                                {selectedValueYear && <div style={{ marginTop: 20, lineHeight: '25px' }}>
                                </div>}
                                </div>

                                <div className="App">
                                <div className="h4">Month</div>
                                <Select className="select"
                                  placeholder="Month"
                                  value={month.find(obj => obj.value === selectedValueMonth)} // set selected value
                                  options={month} // set list of the data
                                  onChange={e => setSelectedValueMonth(e.value)} // assign onChange function
                                />

                                {selectedValueMonth && <div style={{ marginTop: 20, lineHeight: '25px' }}>
                                </div>}
                                </div>

                                <div className="App" >
                                  <div className="h4">Day</div>
                                  <Select className="select"
                                    placeholder="Day"
                                    value={day.find(obj => obj.value === selectedValueDay)} // set selected value
                                    options={day} // set list of the data
                                    onChange={e => setSelectedValueDay(e.value)}// assign onChange function
                                    //onChange = { }
                                  />
                            
                                  {selectedValueDay && <div style={{ marginTop: 20, lineHeight: '25px' }}>
                                  </div>}
                                </div>

                             {/* <div className="App"> <Example></Example></div>*/}
                              
                  
                                <div class="break"></div>
                             {/*    <div>{CheckList(handleDatum(selectedValueDay,selectedValueMonth,selectedValueYear))}</div>


                        {/*     <div>"   {handleDatum(selectedValueDay,selectedValueMonth,selectedValueYear)}   "</div>
                {/*     <div>tokenByIndex:
                       tokenByIndex({handleDatum(selectedValueDay,selectedValueMonth,selectedValueYear)}
                     </div>*/}
                     <button type="button" className="btn btn-outline-dark start start-two" href="#"    disabled={claimingNft ? 1 : 0 || buttonVariable ? 1 : 0}
                      onClick={(e) => {
                        //updateList(handleDatum(selectedValueDay,selectedValueMonth,selectedValueYear),0);
                        e.preventDefault();
                        claimNFTs(handleDatum(selectedValueDay,selectedValueMonth,selectedValueYear));
                        //claimNFTs(1, selectedValueDay);
                        getData();
                      }} >
                      {claimingNft ? "BUSY" : "MINT"}
                    </button>
 
                   {/*  <StyledButton
                      disabled={claimingNft ? 1 : 0}
                      onClick={(e) => {
                        e.preventDefault();
                        console.log(handleDatum(1,1,1971));
                        claimNFTs(1, handleDatum(selectedValueDay,selectedValueMonth,selectedValueYear));
                        //claimNFTs(1, selectedValueDay);
                        getData();
                      }}
                    >
                      {claimingNft ? "BUSY" : "BUY"}
                    </StyledButton>

                    {/*<StyledImg alt={"example"} src={`./images/${selectedValueDay}.png`} />
                    <PreviewImg alt={"preview"} src={require(`../../assets/images/pics/cal-${selectedValueYear}_${selectedValueMonth}_${selectedValueDay}.png`)} />
                    */}
                    <div> &nbsp;&nbsp;&nbsp;</div>
                    {/*<PreviewImg alt={""} src={'images/pics/cday-'+selectedValueYear+'_'+selectedValueMonth+'_'+selectedValueDay+'.png'} onError={(e)=>{e.target.onerror = null; e.target.src='images/pics/soon.gif'}} />*/}
                   <PreviewImg alt={""} src={'images/pics/cday-'+selectedValueYear+'_'+selectedValueMonth+'_'+selectedValueDay+'.png'} onError={(e)=>{e.target.onerror = null; e.target.src='images/pics/soon.gif'}} />
                    {/* <PreviewImg alt={""} src={'https://drive.google.com/uc?export=view&id='+drive.find(obj => obj.value === ''+selectedValueYear+selectedValueMonth+selectedValueDay+'').label} />*/}
                   <div class="break"></div>
                   <div className="h4 previewText">Just a Preview!</div>
                    {/* PreviewImg alt={"example"} src={require(`./images/1.png`)} />
                 <img src={require('./images/1.png').default} />*/}

                  </s.Container2>
                )}
              </>
            )}
          </s.Container>

        </ResponsiveWrapper>
        {/* <s.SpacerSmall />*/}
        <s.Container jc={"center"} ai={"center"}>
          <s.TextDescriptionSub >
            Please make sure you are connected to the right network (Ethereum
            Mainnet) and the correct address. Please note: Once you make the
            purchase, you cannot undo this action.
          </s.TextDescriptionSub>
          <s.SpacerSmall />
          <s.TextDescriptionSub >
            We have set the gas limit to 240000 for the contract to successfully
            mint your NFT. We recommend that you don't change the gas limit.
          </s.TextDescriptionSub>
        </s.Container>
      </s.Container>
    </s.Screen>
    
  );
}

export default Content;