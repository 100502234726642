import React from "react";
import sun from "../../assets/images/sun.png";
import bottom_preview from "../../assets/images/bottom_preview2.png";
import attributes from "../../assets/images/attributes3.png";

function Content2() {
    return (
        <div class = "content2">

            <section class=" content5">
                <div class="container ">
                <br /><br /><br /><br />
              
                </div>
            </section>
            <div className="container content container2" style={{ textAlign: "center" }}>
                <div className="row">

                    <div className="col-sm-12 talk" id="attributes">
                    <div id="contentAcc" >
                        <img src={sun} alt="sun" class="ribbon"/>
                        </div>
                        <h1 >The Attributes</h1>
                        <br /><br /><br />
                        <h6 className="no-bold text">
                        <div className="paddingText">Besides the different color- and pattern-variations of the BODY and HEADER, there are several different kind of attributes. <br />
                        All attributes are randomly distributed among the CrypoDays with different frequency.</div><br />
              
                            <img src={attributes} alt="attributes" class="attributes"/>
                      
                        <br /><br />
                        <div className="paddingText"> The Collection considers the metronomical seasons, so the WEATHER, ACCESSORY and 
                        BOTTOM changes accordingly during the year.</div><br />

                        <br />
                        <img src={bottom_preview} alt="bottomPreview" class="bottomPreview" />
                        <br /><br />
                        Additionally, there are BALLOONs, PRESENTs and (CUP)CAKEs spreaded over the whole Collection each year.<br /><br />
                        <ul class="c">
                            <li>Specials for the holidays</li>
                            <li> & other stuff :)</li>
                        </ul>

                        <br />
                        It's not always about the NFT with the most attributes - sometimes the simplest are the perfect ones.<br />
                        <br /><br /><br />

                    </h6>
                        </div>
                </div>
            </div>

            <section class=" content5" >
                <div class="container ">
                <br /><br /><br /><br />
              
                </div>
            </section>

       
        </div>
    );
}

export default Content2;


/*                        <ul class="c">
                            <li>Spring: March, April, May</li>
                            <li>Summer: June, July, August</li>
                            <li>Autumn: September, October, November</li>
                            <li>Winter: December, January, February</li>
                        </ul>*/