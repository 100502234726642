import styled from "styled-components";

// Used for wrapping a page component

/*
  background-color: var(--dark-grey);
  background-image: ${({ image }) => (image ? `url(${image})` : "none")};
  background-size: cover;
  background-position: center;*/
export const Screen = styled.div`
display: flex;
flex-direction: row;
`;

// Used for providing space between components
export const SpacerXSmall = styled.div`
  height: 8px;
  width: 8px;
`;

// Used for providing space between components
export const SpacerSmall = styled.div`
  height: 16px;
  width: 16px;
`;

// Used for providing space between components
export const SpacerMedium = styled.div`
  height: 24px;
  width: 24px;
`;

// Used for providing space between components
export const SpacerLarge = styled.div`
  height: 32px;
  width: 32px;
`;

// Used for providing a wrapper around a component
//background-image: ${({ image }) => (image ? `url(${image})` : "none")};
//background-color: ${({ test }) => (test ? "pink" : "none")};
//background-size: cover;
export const Container = styled.div`
  display: flex;
  flex: ${({ flex }) => (flex ? flex : 0)};
  flex-direction: ${({ fd }) => (fd ? fd : "column")};

  align-items: ${({ ai }) => (ai ? ai : "flex-start")};
  width: 100%
  background-position: center;
`;

export const Container2 = styled.div`
  display: flex;
  flex: ${({ flex }) => (flex ? flex : 0)};
  flex-direction: ${({ fd }) => (fd ? fd : "row")};
  flex-wrap: wrap;
  align-items: ${({ ai }) => (ai ? ai : "flex-start")};
  width: 100%;
  background-position: center;
  justify-content: center;
`;

export const TextTitle = styled.p`
  color: var(--white);
  font-weight: 500;
  font-size:10px;
  line-height: 1.6;

  @media (min-width: 600px) {
    font-size:17px;
  }

`;

export const TextHead = styled.p`
  text-align: center;
  font-weight: bold;
  color: var(--white);
  font-size:25px;
  line-height: 1.2;

  @media (min-width: 600px) {
    font-size:40px;
    line-height: 1.6;
  }
`;

export const TextSubTitle = styled.p`
  color: var(--white);
  font-size: 10px;
  line-height: 1.6;
  @media (min-width: 600px) {
    font-size:14px;
  }
`;


export const TextDescriptionSub = styled.p`
  text-align: center;

  color: rgb(78, 78, 78);
  font-size: 6px;
  width:90%;
  line-height: 1.6;
  @media (min-width: 600px) {
    font-size:9px;
    width:73%;
  }
  
`;

export const TextDescription = styled.p`
  color: var(--white);
  font-size: 8px;
  line-height: 1.6;
  @media (min-width: 600px) {
    font-size:12px;
  }
`;


export const TextDescriptionContract = styled.p`
a:link { color:green; text-decoration: none; }
a:visited { color:green; text-decoration: none; }
a:hover { color:#0F5B00; text-decoration: none; }
a:active { color:#0F5B00; text-decoration: underline; }
  color: var(--white);
  font-size: 6px;
  line-height: 1.6;
  @media (min-width: 600px) {
    font-size:8px;
  }
`;


export const StyledClickable = styled.div`
  :active {
    opacity: 0.6;
  }
`;
