import React from "react";
import gallery from "../../assets/images/gallery_1100x1.gif";
function Content3() {
    return (
        <div class = "content3">
            <div className="container content container2" style={{ textAlign: "center" }}>
                <div className="row">
                <div id="content5">

                        </div>
                    <div className="col-sm-12 talk2" id="gallery">
                        <h3>The Gallery</h3>
                        <br />
                        <h6 className="no-bold text howToPadding">

            
                        Updated Gallery will be back online soon! 
                        <br />

                        <img src={gallery} alt="gallery" className="roadmap"/><br />
                        <br />

                    </h6>

                    </div>

                </div>
            </div>

            <section class=" content5">
                <div class="container ">
               
              
                </div>
            </section>
        </div>
    );
}

export default Content3;

/*
  <h6 className="no-bold text howToPadding">

                        Check out our zoomable gallery on  <a href="http://gallery.cryptodays.io" aria-disabled="true" rel="noreferrer"  target={"_blank"} className="alink">gallery.cryptodays.io</a>!<br />
                        <br />

                        <a href="http://gallery.cryptodays.io"  aria-disabled="true" rel="noreferrer"  target={"_blank"}><img src={gallery} alt="gallery" className="roadmap"/></a><br />
                        */