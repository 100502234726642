import React, { Component } from "react"
// import logo from '../../components/Logo/logo.svg';

import Header from '../../components/Header/Header';
import MintPage from './MintPage';
import About from './About';
import TheCryptoDay from './TheCryptoDay';
import Attributes from './Attributes';
import Gallery from './Gallery';
import Roadmap from './Roadmap';
import HowTo from './HowTo';
import Footer from '../../components/Footer/Footer';




class Index extends Component {


    render() {

        
        return (

            <div className="main-container">
                <Header />
                <MintPage />
                <About />
                <TheCryptoDay />
                <Attributes />
                <Gallery />
                <Roadmap />
                <HowTo />
                <Footer />
            </div>

        )
    }
}

export default Index;