import React from "react";
import roadmap from "../../assets/images/roadmap.png";

function Content3() {
    return (
        <div class = "content3">

        <section class=" content5">
                <div class="container ">
                <br /><br /><br /><br />
              
                </div>
            </section>

            <div className="container content container2" style={{ textAlign: "center" }}>
                <div className="row">
                <div id="content5">
                        
                        </div>
                    <div className="col-sm-12 talk" id="roadmap">
                    <h1 className="h3Padding">The Roadmap</h1>
                        <div>
                        
                        <br />
                        <h6 className="no-bold text howToPadding">
                        <img src={roadmap} alt="roadmap" className="roadmap"/>
                       
                    </h6>
        

                    </div>
                    </div>
                </div>
            </div>
            <section class=" content5">
                <div class="container ">
               
              
                </div>
            </section>

        </div>
    );
}

export default Content3;