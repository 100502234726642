import React from "react";
import cakePng from "../../assets/images/cake.png";
import cryptoday_preview from "../../assets/images/cryptoday_preview.png";

function Content2() {
    return (
        <div class = "content2">

            <section class=" content5">
                <div class="container ">
                <br /><br /><br /><br />
              
                </div>
            </section>
            <div className="container content container2">
                <div className="row">

                    <div className="col-sm-12 talk" id="project" style={{ textAlign: "center" }}>
                    
                        <img src={cakePng} alt="cake" class="cake"/>
                        
                        <h1 className="h3Padding">The Project</h1>
                        <br /><br /><br />
                        <h6 className="no-bold text">
                        <div className="paddingText"> CryptoDays is a NFT collection of 56x56 pixel art calendar sheets, randomly created for 63 years ('60 to '22).</div>
                        Choose your favorite date and save it on the Blockchain .. forever!
                        <br />
                        Get your Birthday, your wedding day or the day your favorite team won a Championship ;) 
                        <br /><br /><br />
                        
                        <ul class="b">
                
                            <li>23011 CryptoDays (63 years)</li>
                            <li>56x56px upscaled images</li>
                            <li>Randomly created</li>
                            <li>Ethereum Smart Contract</li>
                            <li>Each CryptoDay costs 0.006 ETH</li>
                           
                            <br />
                            <img src={cryptoday_preview} alt="cryptoday_preview" class="cryptoday_preview"/>

 
                        </ul>
                        <br /><br />
                        <div className="paddingText"> The Collection consists of 23011 NFTs, ready to get minted on an own Ethereum Smart Contract.<br />
                        Each CryptoDay costs 0.006 ETH so everyone is able to mint their favorite Day of the last 63 years.</div>

                        <br /><br />

                    </h6>
                        <br />
                        </div>
                </div>
            </div>

            <section class=" content5">
                <div class="container ">
                <br /><br /><br /><br />
              
                </div>
            </section>

        
        </div>
    );
}

export default Content2;

/*
 <div className="paddingText"> The Collection consists of 23011 NFTs, ready to get minted on an own Ethereum Smart Contract.<br />
                        Each CryptoDay costs 0.01 ETH so everyone is able to purchase their favorite Day of the last 51 years.</div>
                        
                        
                         <li className="blink_text" style={{color: "white"}}>Free for the release week!!</li>*/